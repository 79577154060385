<template>
	<div class="index">
		<headers :headIndex="0"></headers>
        <swiper></swiper>

        <!-- 内容 -->
        <div class="index-content">
            <div class="index-content-list">
                <!-- 新闻 -->
                <div class="index-content-news">
                    <!-- 头部 -->
                    <div class="index-content-news-title">
                        <span>新闻中心</span>
                        <span style="font-size: 12px; padding-top: 9px;">News Center</span>
                    </div>
                    <!-- 内容 -->
                    <div class="index-content-news-list">
                        <div class="index-content-news-item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                            <span class="index-content-news-item-title">
                                {{ item.title }}
                            </span>
                            <span class="index-content-news-item-time">{{ item.releasetimes }}</span>
                        </div>
                    </div>
                    <!-- 脚部 -->
                    <div class="index-content-news-foot"></div>
                </div>
                <!-- 办学 -->
                <div class="index-content-news" style="">
                    <!-- 头部 -->
                    <div class="index-content-news-title">
                        <span>{{teacherList[2].title}}</span>
                        <span style="font-size: 12px; padding-top: 9px;">{{teacherList[2].describe}}</span>
                    </div>
                    <!-- 内容 -->
                    <div class="index-content-news-list">
                       <p v-html="teacherList[2].content"></p>
                    </div>
                    <!-- 脚部 -->
                    <div class="index-content-news-foot" style="background: #DBDAD4;"></div>
                </div>
                
                <!--教学环境 -->
                <div class="index-content-news" style="" >
                    <!-- 头部 -->
                    <div class="index-content-news-title">
                        <span>{{teacherList[1].title}}</span>
                        <span style="font-size: 12px; padding-top: 9px;">{{teacherList[1].describe}}</span>
                    </div>
                    <!-- 内容 -->
                    <div class="index-content-news-list">
                        <p v-html="teacherList[1].content"></p>
                    </div>
                    <!-- 脚部 -->
                    <div class="index-content-news-foot"></div>
                </div>
                
                
                <!-- 图片 -->
                <div class="index-content-news">
                    <!-- 头部 -->
                    <div class="index-content-news-title">
                        <span>{{teacherList[0].title}}</span>
                        <span style="font-size: 12px; padding-top: 9px;">{{teacherList[0].describe}}</span>
                    </div>
                    <!-- 内容 -->
                    <div class="index-content-news-list">
                       <p v-html="teacherList[0].content"></p>
                    </div>
                    <!-- 脚部 -->
                    <div class="index-content-news-foot" style="background: #DBDAD4;"></div>
                </div>
            </div>
        </div>
		<footers></footers>
	</div>
</template>
<script>
    import headers from '@/components/headers'
    import footers from '@/components/footers'
    import swiper from '@/components/swiper'
    export default {
        name: 'index',
        components:{
            headers,
            footers,
            swiper
        },
        data() {
            return {
                list:[],
                page:1,//页
                limit:4,//条
                total:0,//总页数
                count:0,//总条数
                teacherList: [
                    {
                    title: '',
                    describe: '',
                    content: ''
                },{
                    title: '',
                    describe: '',
                    content: ''
                },
                {
                    title: '',
                    describe: '',
                    content: ''
                }]  //办学内容
            }
        },
        created() {
            this.homepage()
            this.getList()//首页新闻中心
        },
        methods: {
            getList(){
                this.$request({
                    url:'/api/article/home_new',
                    method:'POST',
                    data:{}
                }).then(res=>{
                    if(res.code==1){
                        // console.log(res,'首页新闻中心')
                        this.list=res.data
                    }
                })
            },
            homepage(){
                this.$request({
                    url:'/api/article/homepage',
                    method:'POST',
                    data:{}
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,'首页')
                        this.teacherList=res.data
                        // this.teacherList.map(item => {
                        //     console.log( typeof item.title);
                        // })
                    }
                })
            },
            navto(item) {
                this.$router.push({
                    path:'/news/newDetail',
                    query:{
                        id:item.id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
	.index{
		width: 100vw;
        opacity: hidden;
	}
    // 内容
    .index-content{
        width: 100%;
        padding: 16px 0px 35px;
        background: #F6F6EA;
        display: flex;
        justify-content: center;
    }
    @media screen and (max-width: 1200px) {
        .index-content-list{
            width: 100%;
        }
    }
    .index-content-list{
        width: 1200px;
        height: 582px;
        display: flex;
        justify-content: space-between;
    }
    // 新闻
    .index-content-news{
        width: 24.5%;
        display: flex;
        flex-direction: column;
    }
    .index-content-news-title{
        width: 100%;
        padding: 15px 19px;
        color: #ffffff;
        background: #A4B3A9;
        display: flex;
        flex-direction: column;
        font-size: 24px;
        margin-bottom: 9px;
    }
    // 内容
    .index-content-news-list{
        flex: 1;
        overflow: hidden;
        background: rgba($color: #FFFFFF, $alpha: 0.7);
        padding: 9px 19px;
        display: flex;
        flex-direction: column;
        img {
            width: 100%;
        }
    }
    .index-content-news-item{
        width: 100%;
        margin-top: 17px;
        color: #333333;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding-bottom: 10px;
        border-bottom: 1px solid #707070;
    }
    .index-content-news-item:hover{
        color: #26716C;
        border-bottom-color: #26716C;
        cursor: pointer;
    }
    // 标题
    .index-content-news-item-title{
        width: 100%;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    // 时间
    .index-content-news-item-time{
        padding-top: 10px;
        font-size: 12px;
    }

    // 办学
    .index-content-news-img{
        width: 100%;
        height: 164px;
    }
    .index-content-teacher-list{
        padding-top: 40px;
        padding: 40px 0 0 19px ;
        color: #333333;
        font-size: 14px;
        max-height: 220px;
        // overflow: hidden;
    }
    .index-content-teacher-list li{
        padding-bottom: 24px;
    }
    ul li::marker {
        color: #FF2929;
    }

    .index-content-teacher{
        width: 100%;
        height: 146px;
        margin-bottom: 5px;
    }
    // 脚部
    .index-content-news-foot{
        margin-top: 8px;
        background: #9E9E9E;
        // padding: 6px 0;
        height: 40px;
        text-align: center;
        color: #ffffff;
        // cursor: pointer;
    }
</style>
