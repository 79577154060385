<template>
	<div class="swiper" :style="{'height': height+'px'}" @mousedown="swiperdown($event)" @mouseup.prevent="swiperUp($event)">
		<div class="swiper-list" ref="swiper" :style="{'transform': 'translateX('+(-left)+'px)'}" :class="isTranstion ? 'swiper-list-active' : ''">
			<img v-for="(item, index) in imgs" :key="index" :src="item.image" @mousedown="addTop($event)"/>
		</div>
		<!-- 控制器 -->
		<div class="swiper-controller">
			<div class="swiper-controller-item" v-for="(item, key) in controller" :key="key" @mousedown="mousedown" @mouseup="mouseup(key)" :class="key == index ? 'swiper-controller-item-active' : ''"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "swiper",
		props: {
			// 速度，  默认3s
			speed: {
				type: Number,
				default: 3000
			},
			height: {
				type: Number,
				default: 630
			}
		},
		data() {
			return {
				index: 0,   // 当前处在哪一个
				left: 0,  //位移距离  -
				isTranstion: false,  //判断是否到达临界点
				imgs: [],//轮播图数组
				controller: 0, // 控制器个数
				timer: null, //轮播方法
				clientWidth: 0, //整体宽度
				clientx: 0, //鼠标滑动初始x
			}
		},
        created() {
            this.$request({
                url:'/api/article/banner',
                method:'POST',
                data:{}
            }).then(res=>{
                if(res.code==1){
                    // console.log(res,'轮播图片')
                    this.imgs = res.data
                    this.controller = this.imgs.length
                }
            })
        },
		mounted() {
			this.clientWidth = this.$refs.swiper.clientWidth
			// 设置定时器
			this.timer = this.interval()
            
		},
		methods: {
			// 阻止图片默认事件
			addTop(e) {
				e.preventDefault();//取消默认事件
			},
			// 定时器方法
			interval() {
				let swiper = this.$refs.swiper
				let clientWidth = this.clientWidth
				return setInterval(() => {
							if(this.index++ >= this.imgs.length-1) {
								this.swiperMove()
							}
							this.left = clientWidth*this.index
						}, this.speed)
			},
			// 鼠标事件
			mousedown() {
				clearInterval(this.timer)
			},
			mouseup(index){
				this.index = index
				this.left = this.clientWidth * this.index
				this.timer = this.interval()
			},
			// 滑动屏幕事件
			// 按下
			swiperdown(e) {
				this.clientx = e.clientX
				this.mousedown()
			},
			//  拖拽方法
			swiperMove(flag = true) {
				// 反向拖拽， 正向移动
				if(flag) {
					this.imgs.push(this.imgs[0])
					setTimeout(() => {
						this.isTranstion = true
						this.index = 0
						this.left = 0
						this.imgs.pop()
						setTimeout(() => {
							this.isTranstion = false
						}, 500)
					}, 3000)
				} else {    // 正向拖拽， 反向移动
					this.isTranstion = true
					this.imgs.push(this.imgs[0])
					this.index = this.imgs.length-1
					this.left = this.index * this.clientWidth
					setTimeout(() => {
						this.isTranstion = false
						this.index--
						this.mouseup(this.index)
						setTimeout(() => {
							this.imgs.splice(this.imgs.length-1, 1)
						}, 1000)
					}, 0)
				}
				
			},
			// 松起
			swiperUp(e) {
				let upx = e.clientX
				// 从左往右拖拽， 逆向滚动
				if(upx - this.clientx > 0 && upx - this.clientx > 50) {
					if(this.index - 1 < 0 ) {
						this.swiperMove(false)
					} else {
						this.index--
						this.mouseup(this.index)
					}
				} else if(upx - this.clientx < 0 && Math.abs(upx - this.clientx) > 50){   // 从右往左拖拽， 顺向滚动
					if(this.index++ >= this.imgs.length-1) {
						this.swiperMove()
					}
					this.left = this.clientWidth * this.index
					this.timer = this.interval()
				}
			}
		}
	}
</script>

<style lang="scss" scoped >
	.swiper{
		width: 100%;
		height: 630px;
		position: relative;
		overflow: hidden;
	}
	.swiper-list{
		width: 100%;
		height: 100%;
		z-index: 0;
		white-space: nowrap;
		// display: flex;
		transform: translateX(-200px);
		transition: all 1s;
	}
	.swiper-list-active{
		transition: none;
	}
	.swiper-list img{
		width: 100%;
		height: 100%;
	}
	// 控制器
	.swiper-controller{
		display: flex;
		align-items: center;
		position: absolute;
		right: 5%;
		bottom: 5%;
	}
	.swiper-controller-item{
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #333333;
		margin-right: 20px;
		transition: all 1s;
		cursor: pointer;
	}
	.swiper-controller-item-active{
		background: #ffffff;
	}
</style>
